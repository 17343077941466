.video {
  /* margin-left: 40px; */
  border-radius: 200px;
}
body {
  /* background: rgb(85, 37, 131);
  background: linear-gradient(
    90deg,
    rgba(85, 37, 131, 1) 33%,
    rgba(253, 185, 39, 1) 100%
  ); */
  background-image: url("./lebr.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
  font-family: "Barlow Condensed", sans-serif;
}
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
}
.main-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.nav-div {
  margin-top: 20px;
  width: 300px;
  justify-content: space-around;
  margin-right: 20px;
}
.nav-link {
  margin-left: 10px;
  margin-right: 10px;
  width: 100%;
  cursor: pointer;
  text-decoration: none;
  color: white;
  font-size: 18px;
  padding: 10px;
  position: relative;
  display: inline-block;
  background-color: black;
}
a {
  /* padding: 10px;
  position: relative;
  display: inline-block;
  background-color: black; */
  /* overflow: hidden; */
}

/* a:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background: black;
  transition: height 0.3s ease-in-out;
  z-index: -1;
}

a:hover:before {
  height: 100%;
} */
p {
  font-size: 18px;
  cursor: default;
}
button {
  background-color: black;
  border: none;
  cursor: pointer;
  color: white;
  width: 100%;
  height: 50px;
  border-radius: 20px;
}
