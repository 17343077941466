@font-face {
  font-family: "Lakers";
  src: local("Lakers"), url("./fonts/Lakers.ttf") format("truetype");
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
